export const DEFAULT_PAGE_SIZE = 20;
export const TABLE_BORDER_COLOR = "#f1f5f1";
export const NO_RECORD = "No Record Found.";
export const ADMIN_ROLE = 1;
export const LAB_ROLE = 3;
export const EMPLOYER_ROLE = 2;
export const EMPLOYEE_ROLE = 4;
export const EMPLOYER_STATUS = {
  PENDING: 1, //no action / pending
  ACTIVE: 2,
  REJECTED: 3,
};

export const staffRoleOptions = [
  {
    title: "CNA",
    value: "CNA",
  },
  {
    title: "Nurse",
    value: "Nurse",
  },
  {
    title: "Medtech",
    value: "Medtech",
  },
  {
    title: "Nurse, LPN",
    value: "Nurse_LPN",
  },
  {
    title: "Nurse, RN",
    value: "Nurse_RN",
  },
  {
    title: "Nurse Practitioner",
    value: "Nurse_Practitioner",
  },
  {
    title: "Phlebotomist",
    value: "Phlebotomist",
  },
];

export const physicianRoleOptions = [
  {
    title: "Physician Assistant",
    value: "Physician_Assistant",
  },
  {
    title: "Physician",
    value: "Physician",
  },
  {
    title: "Specialist",
    value: "Specialist",
  },
];

export const STAFF_ROLE = 5;
export const PHYSICIAN_ROLE = 6;
export const PHLEBOTOMIST_ROLE = 7;
export const NURSE_ROLE = 8;
export const TEST_ORDER_STATUS = [
  {
    value: 1,
    label: "New",
  },
  {
    value: 2,
    label: "Scheduled",
  },
  {
    value: 3,
    label: "Sample Collected",
  },
  {
    value: 4,
    label: "Results Delivered",
  },
  {
    value: 5,
    label: "Cancelled",
  },
  {
    value: 6,
    label: "Partially Delivered",
  },
  {
    value: 7,
    label: "Pending",
  },
  {
    value: 8,
    label: "Confirmed",
  },
];
export const RECURRING_ORDER_STATUS = [
  {
    value: 1,
    label: "Inactive",
  },
  {
    value: 2,
    label: "Active",
  },
  {
    value: 3,
    label: "Completed",
  },
];

export const collectionTypes = {
  covid19: ["Saliva", "Nasopharyngeal"],
  respiratoryPanel: ["Nasal", "Nasopharyngeal"],
  employment: ["Hair", "Oral", "Urine", "Blood", "Nasal-Swab", "Cheek-Swab"],
};

export const globalSpecimenTypes = [
  {
    value: "Hair",
    label: "Hair",
  },
  {
    value: "Oral",
    label: "Oral",
  },
  {
    value: "Urine",
    label: "Urine",
  },
  {
    value: "Blood",
    label: "Blood",
  },
  {
    value: "Nasal-Swab",
    label: "Nasal Swab",
  },
  {
    value: "Cheek-Swab",
    label: "Cheek Swab",
  },
  {
    value: "Saliva",
    label: "Saliva",
  },
  {
    value: "Nasopharyngeal",
    label: "Nasopharyngeal",
  },
  {
    value: "Nasal",
    label: "Nasal",
  },
  {
    value: "Nasopharyngeal",
    label: "Nasopharyngeal",
  },
];

export const MappingOfTestWithCollectionTypes = {
  "covid-19": "covid19",
  "respiratory-panel": "respiratoryPanel",
  antibody: "antibody",
  antigen: "antigen",
};

export const ethnicityOptions = [
  {
    value: "1",
    label: "HISPANIC",
    regex: new RegExp("1", "i"),
  },
  {
    value: "2",
    label: "NON - HISPANIC",
    regex: new RegExp("2", "i"),
  },
];

export const paymentByOptions = [
  {
    value: "1",
    label: "Organization",
    regex: new RegExp("1", "i"),
  },
  {
    value: "2",
    label: "Insurance",
    regex: new RegExp("2", "i"),
  },
  {
    value: "3",
    label: "Self",
    regex: new RegExp("3", "i"),
  },
];

export const physicianFromOptions = [
  {
    value: "1",
    label: "iLabConnect",
    regex: new RegExp("1", "i"),
  },
  {
    value: "2",
    label: "Organization",
    regex: new RegExp("2", "i"),
  },
];

export const raceOptions = [
  {
    value: "1",
    label: "American Indian, Alaska Native",
  },
  {
    value: "2",
    label: "Asian",
  },
  {
    value: "3",
    label: "Black or African American",
  },
  {
    value: "4",
    label: "White",
  },
  {
    value: "5",
    label: "Other",
  },
];

export const genderOptions = [
  {
    value: "m",
    label: "Male",
  },
  {
    value: "f",
    label: "Female",
  },
  {
    value: "o",
    label: "Other",
  },
];

export const resultOptions = [
  {
    value: "positive",
    label: "Positive",
  },
  {
    value: "negative",
    label: "Negative",
  },
  {
    value: "inconclusive",
    label: "Inconclusive",
  },
  {
    value: "tnp",
    label: "Test not performed",
  },
];

export const organizationBulkOperationOptions = [
  {
    value: "1",
    label: "Send username",
    regex: new RegExp("1", "i"),
  },
  {
    value: "2",
    label: "Send reset password link",
    regex: new RegExp("2", "i"),
  },
  {
    value: "3",
    label: "Move patients",
    regex: new RegExp("3", "i"),
  },
];

export const testOrderBulkOperationOptions = [
  {
    value: "1",
    label: "Generate barcode",
    regex: new RegExp("1", "i"),
  },
  {
    value: "2",
    label: "Generate requisition form",
    regex: new RegExp("2", "i"),
  },
  {
    value: "3",
    label: "Generate HL7 billing files(it will push the files to HL7 server)",
    regex: new RegExp("3", "i"),
  },
  {
    value: "4",
    label:
      "Generate HL7 Order files(it will push the files to HL7 server inbound)",
    regex: new RegExp("4", "i"),
  },
];

export const vaccineTypeOptions = [
  {
    value: "0",
    label: "Unvaccinated",
  },
  {
    value: "1",
    label: "Pfizer",
  },
  {
    value: "2",
    label: "Moderna",
  },
  {
    value: "3",
    label: "Johnson and Johnson",
  },
];

export const regionsList = [
  { label: "Central", value: "Central" },
  { label: "Chesapeake", value: "Chesapeake" },
  { label: "Corporate", value: "Corporate" },
  { label: "MidAtlantic", value: "MidAtlantic" },
  { label: "Midwest", value: "Midwest" },
  { label: "North America", value: "North America" },
  { label: "Northeast", value: "Northeast" },
  { label: "Northwest", value: "Northwest" },
  { label: "Southeast", value: "Southeast" },
  { label: "Southwest", value: "Southwest" },
  { label: "TKO", value: "TKO" },
  { label: "West", value: "West" },
];

export const jobCodes = [
  { value: 110, label: "Services - Sciences" },
  { value: 114, label: "Enterprise Corporate Services" },
  { value: 115, label: "Operations & Administrative" },
  { value: 118, label: "Distrib & Logistics" },
  { value: 119, label: "Const, Env, & Energy" },
  { value: 120, label: "Facilities & Maint" },
  { value: 16, label: "Customer Support" },
  { value: 2, label: "Contract Engineering" },
  { value: 50, label: "Services - Engineering" },
  { value: 54, label: "Accounting and Finance" },
  { value: 6, label: "Manu, Robo, & Autom" },
  { value: 66, label: "Aviation" },
  { value: 8, label: "Contract Sciences" },
];

export const reasonForTestList = [
  {
    value: 1,
    label: "Pre-Employment testing",
  },
  {
    value: 2,
    label: "Random testing",
  },
  {
    value: 3,
    label: "Post-Accident testing",
  },
  {
    value: 4,
    label: "Reasonable suspicion testing",
  },
  {
    value: 5,
    label: "Return to duty testing",
  },
  {
    value: 6,
    label: "Medical check to complete I693",
  },
];

export const employerRoleOptions = [
  {
    value: 1,
    label: "Admin",
  },
  {
    value: 2,
    label: "Field Staff",
  },
];

export const mroCertifyingBody = [
  {
    value: "AAMRO",
    label: "AAMRO",
  },
  {
    value: "ACOEM",
    label: "ACOEM",
  },
  {
    value: "AOCOPM",
    label: "AOCOPM",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const employmentTest = [
  {
    value: "covid-19",
    label: "Covid-19 PCR",
  },
  {
    value: "respiratory-panel",
    label: "Respiratory Panel",
  },
  {
    value: "employment",
    label: "Employment Testing",
  },
];

export const globalServices = [
  {
    value: "drug",
    label: "Drug Tests",
  },
  {
    value: "lab",
    label: "Lab Tests",
  },
  {
    value: "medical",
    label: "Medical Services",
  },
];

export const reasonToCancelList = [
  {
    value: "No_Show",
    label: "No Show",
  },
  {
    value: "Duplicate_Order",
    label: "Duplicate Order",
  },
  {
    value: "Applicant_Withdrew_from_Job",
    label: "Applicant Withdrew from Job",
  },
  {
    value: "Accidental_Order_Entry",
    label: "Accidental Order Entry",
  },
  {
    value: "Scheduling_Conflict",
    label: "Scheduling Conflict",
  },
  {
    value: "System_Error",
    label: "System Error",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const productServices = [
  {
    value: "Client-Bill",
    label: "Client Bill",
  },
  {
    value: "Insurance",
    label: "Insurance",
  },
  {
    value: "Self-Pay",
    label: "Self Pay",
  },
  {
    value: "Third-Party",
    label: "Third Party",
  },
  {
    value: "Workers-Compensation",
    label: "Workers Compensation",
  },
];

export const GlobalServices = [
  // TypesOfBusinessGlobal
  {
    value: "Employment-Health",
    label: "Employment Health",
  },
  {
    value: "Disease-Management",
    label: "Disease Management (DMS)",
  },
  {
    value: "Wellness",
    label: "Wellness (WNS)",
  },
  {
    value: "Infectious-Disease",
    label: "Infectious Disease",
  },
];

export const orderDurations = [
  {
    value: "one-month",
    label: "One Month",
  },
  {
    value: "two-month",
    label: "Two Month",
  },
  {
    value: "three-month",
    label: "Three Month",
  },
];

export const productDurationType = [
  {
    value: "fixed",
    label: "Fixed",
  },
  {
    value: "range",
    label: "Range",
  },
];

export const productDurationUnit = [
  {
    value: "days",
    label: "Days",
  },
  {
    value: "hours",
    label: "Hours",
  },
];
