import {
  EMPLOYER_CREATED,
  EMPLOYER_ERROR,
  EMPLOYER_DELETE,
  EMPLOYER_UPDATED,
  EMPLOYER_LIST_UPDATED,
  GET_EMPLOYER_BY_ID,
  EMPLOYER_SEARCH_PARAMATERS_UPDATE,
  CHANGE_EMPLOYER_STATUS,
  LOADING_ON_EMPLOYER_SUBMIT,
  RESET,
  LOAD_PAGE,
  LOADING_EMPLOYER_LIST_DATA,
  EMPLOYER_EMPLOYEES_LIST_UPDATED,
  LOADING_COMPANYADMIN_LIST,
  ADMIN_COMPANYADMIN_LIST,
  EMPLOYER_ARCHIVED,
  LOADING_EMPLOYER_GLOBAL_PATIENT_LIST_DATA,
  EMPLOYER_GLOBAL_PATIENT_SEARCH_PARAMATERS_UPDATE,
  EMPLOYER_GLOBAL_PATIENT_LIST_UPDATED,
  LOADING_ON_PRODUCT_PRICING_SUBMIT,
  PRODUCT_PRICING_UPDATED,
  LOADING_PRODUCT_PRICING_LIST_DATA,
  EMPLOYER_PRODUCT_PRICING_LIST_UPDATED,
  CHANGE_PRODUCT_PRICING_STATUS,
  UPDATE_PRODUCT_PRICE,
  DELETE_PRODUCT_PRICE,
  PRODUCT_PRICING_SEARCH_PARAMATERS_UPDATE,
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  employersList: {
    page: 1,
    data: [],
    count: 0,
  },
  employeesList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentEmployerAdmin: null,
  currentEmployer: null,
  loadingEmployerList: true,
  loadingEmployer: true,
  loadingListData: true,
  loadingCompanyAdmins: false,
  loadingEmployerAdmin: false,
  performDelete: false,
  companyAdmins: [],
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters: [],
  },
  loadingGlobalPatientListData: true,
  sortingGlobalPatientParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters: [],
  },
  globalPatientsList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentEmployerProduct: null,
  loadingEmployerProduct: true,
  sortingProductsPricingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  },
  productsPricingList: {
    page: 1,
    data: [],
    count: 0,
  },
  loadingProductsPricingList: true,
  loadingProductsPricingListData: true,
  performProductsPricingDelete: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return {
        ...initialState,
        loadingEmployer: state.loadingEmployer,
        loadingEmployerList: state.loadingEmployerList,
        loadingListData: state.loadingListData,
        loadingCompanyAdmins: state.loadingCompanyAdmins,
        loadingEmployerAdmin: state.loadingEmployerAdmin,
        loadingEmployerProduct: state.loadingEmployerProduct,
        loadingProductsPricingList: state.loadingProductsPricingList,
        loadingProductsPricingListData: state.loadingProductsPricingListData,
      };
    case LOAD_PAGE:
      return {
        ...state,
        loadingEmployer: false,
      };
    case EMPLOYER_CREATED:
      return {
        ...state,
        loadingEmployer: false,
      };
    case LOADING_COMPANYADMIN_LIST:
      return {
        ...state,
        loadingCompanyAdmins: true,
      };
    case ADMIN_COMPANYADMIN_LIST:
      return {
        ...state,
        companyAdmins: payload,
        loadingCompanyAdmins: false,
      };
    case EMPLOYER_UPDATED:
      return {
        ...state,
        currentEmployer: payload,
        sortingParams: initialState.sortingParams,
        loadingEmployer: false,
      };
    case EMPLOYER_DELETE:
      const currentCount = state.employersList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.employersList.page);
      let remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      remainingPages = remainingPages ? remainingPages : 1;
      return {
        ...state,
        employersList: {
          data: state.employersList.data.filter(
            (employer) => employer._id !== payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        loadingEmployerList: false,
        performDelete: true,
      };
    case EMPLOYER_ERROR:
      return {
        ...state,
        error: payload,
        loadingEmployer: false,
        loadingEmployerList: false,
        loadingListData: false,
        performDelete: false,
        loadingCompanyAdmins: false,
        loadingEmployerAdmin: false,
        loadingProductsPricingList: false,
        loadingProductsPricingListData: false,
        performProductsPricingDelete: false,
      };
    case GET_EMPLOYER_BY_ID:
      return {
        ...state,
        currentEmployer: payload,
        loadingEmployer: false,
      };
    case EMPLOYER_LIST_UPDATED:
      return {
        ...state,
        employersList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        // loadingEmployer: true,
        loadingEmployerList: false,
        loadingListData: false,
        performDelete: false,
      };
    case EMPLOYER_EMPLOYEES_LIST_UPDATED:
      return {
        ...state,
        employeesList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        // loadingEmployer: true,
        loadingEmployerList: false,
        loadingListData: false,
        performDelete: false,
      };
    case EMPLOYER_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };
    case CHANGE_EMPLOYER_STATUS:
      return {
        ...state,
        employersList: {
          ...state.employersList,
          data: state.employersList.data.map((employer) =>
            employer._id === payload._id
              ? { ...employer, status: payload.status }
              : employer
          ),
        },
      };
    case LOADING_EMPLOYER_LIST_DATA:
      return {
        ...state,
        loadingListData: true,
      };
    case LOADING_ON_EMPLOYER_SUBMIT:
      return {
        ...state,
        loadingEmployer: true,
      };
    case EMPLOYER_ARCHIVED:
      const currentCount1 = state.employersList.count;
      const currentLimit1 = state.sortingParams.limit;
      const currentPage1 = parseInt(state.employersList.page);
      let remainingPages1 = Math.ceil((currentCount1 - 1) / currentLimit1);
      remainingPages1 = remainingPages1 ? remainingPages1 : 1;
      return {
        ...state,
        employersList: {
          data: state.employersList.data.filter(
            (employer) => employer._id !== payload
          ),
          count: currentCount1 - 1,
          page:
            currentPage1 <= remainingPages1
              ? currentPage1.toString()
              : remainingPages1.toString(),
        },
        loadingEmployerList: false,
        performDelete: true,
      };
    case LOADING_EMPLOYER_GLOBAL_PATIENT_LIST_DATA:
      return {
        ...state,
        loadingGlobalPatientListData: true,
      };
    case EMPLOYER_GLOBAL_PATIENT_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingGlobalPatientParams: { ...payload },
      };
    case EMPLOYER_GLOBAL_PATIENT_LIST_UPDATED:
      return {
        ...state,
        globalPatientsList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        loadingGlobalPatientListData: false,
      };
    case LOADING_ON_PRODUCT_PRICING_SUBMIT:
      return {
        ...state,
        loadingEmployer: true,
        loadingEmployerProduct: true,
      };
    case PRODUCT_PRICING_UPDATED:
      return {
        ...state,
        currentEmployerProduct: payload,
        sortingProductsPricingParams: initialState.sortingProductsPricingParams,
        loadingEmployer: false,
        loadingEmployerProduct: false,
      };
    case PRODUCT_PRICING_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        currentEmployerProduct: payload,
        sortingProductsPricingParams: { ...payload },
      };
    case CHANGE_PRODUCT_PRICING_STATUS:
      return {
        ...state,
        loadingProductsPricingListData: false,
        productsPricingList: {
          ...state.productsPricingList,
          data: state.productsPricingList.data.map((item) =>
            item._id === payload._id
              ? { ...item, status: payload.status }
              : item
          ),
        },
      };
    case LOADING_PRODUCT_PRICING_LIST_DATA:
      return {
        ...state,
        loadingProductsPricingListData: true,
      };
    case EMPLOYER_PRODUCT_PRICING_LIST_UPDATED:
      return {
        ...state,
        currentEmployerProduct: payload,
        productsPricingList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        loadingProductsPricingList: false,
        loadingProductsPricingListData: false,
        performProductsPricingDelete: false,
      };
    case UPDATE_PRODUCT_PRICE:
      return {
        ...state,
        currentEmployerProduct: payload,
        productsPricingList: {
          ...state.productsPricingList,
          data: state.productsPricingList.data.map((item) =>
            item._id === payload._id ? { ...item, price: payload.price } : item
          ),
        },
        loadingProductsPricingListData: false,
      };
    case DELETE_PRODUCT_PRICE:
      const currentProductsPricingCount = state.productsPricingList.count;
      const currentProductsPricingLimit =
        state.sortingProductsPricingParams.limit;
      const currentProductsPricingPage = parseInt(
        state.productsPricingList.page
      );
      let remainingProductsPricingPages = Math.ceil(
        (currentProductsPricingCount - 1) / currentProductsPricingLimit
      );
      remainingProductsPricingPages = remainingProductsPricingPages
        ? remainingProductsPricingPages
        : 1;
      return {
        ...state,
        productsPricingList: {
          data: state.productsPricingList.data.filter(
            (item) => item._id !== payload
          ),
          count: currentProductsPricingCount - 1,
          page:
            currentProductsPricingPage <= remainingProductsPricingPages
              ? currentProductsPricingPage.toString()
              : remainingProductsPricingPages.toString(),
        },
        loadingProductsPricingList: false,
        loadingProductsPricingListData: false,
        performProductsPricingDelete: true,
      };
    default:
      return state;
  }
}
