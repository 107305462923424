import axios from "axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import {
  ADMIN_TEST_ORDER_CREATED,
  ADMIN_TEST_ORDER_ERROR,
  ADMIN_TEST_ORDER_DELETE,
  ADMIN_TEST_ORDER_UPDATED,
  ADMIN_TEST_ORDER_LIST_UPDATED,
  ADMIN_TEST_ORDER_GET_BY_ID,
  ADMIN_TEST_ORDER_RESULT_DELETE_BY_ID,
  ADMIN_TEST_ORDER_SEARCH_PARAMATERS_UPDATE,
  REMOVE_ERRORS,
  ADMIN_TEST_ORDER_CHANGE_STATUS,
  ADMIN_TEST_ORDER_LOADING_ON_SUBMIT,
  ADMIN_TEST_ORDER_LOADING_LIST_DATA,
  RESET,
  LOAD_PAGE,
  ADMIN_TEST_ORDER_EMPLOYERS_LIST,
  ADMIN_TEST_ORDER_STAFF_LIST,
  ADMIN_TEST_ORDER_PHYSICIAN_LIST,
  ADMIN_TEST_ORDER_PHLEBOTOMIST_LIST,
  ADMIN_TEST_ORDER_NURSE_LIST,
  ADMIN_TEST_ORDER_CANCEL_SUBMIT,
  ADMIN_TEST_ORDER_CANCEL_COMPLETE,
  ADMIN_TEST_ORDER_NOTE_ERROR,
  ADMIN_TEST_ORDER_NOTE_CREATED,
  ADMIN_TEST_ORDER_LOADING_ON_NOTE_SUBMIT,
  ADMIN_TEST_ORDER_GET_NOTES_BY_ORDER_ID,
  ADMIN_TEST_ORDER_PATIENT_DATA,
  ADMIN_TEST_ORDER_BARCODE_ADDED,
  ADMIN_TEST_ORDER_ARCHIVED,
  ADMIN_TEST_ORDER_BARCODING_STATUS,
  ADMIN_TEST_ORDER_CHANGE_STATUS_SPECIFIC,
  ADMIN_TEST_ORDER_EMPLOYEE_EMPLOYER_INFO,
  ADMIN_TEST_ORDER_ORDER_ITEM_REMOVED,
} from "actions/types";

import {
  STAFF_ROLE,
  PHYSICIAN_ROLE,
  PHLEBOTOMIST_ROLE,
  NURSE_ROLE,
} from "../../constants";

import { logout } from "actions/auth";

//get employers list
export const getOrdersList = (testOrderParams) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    dispatch({ type: REMOVE_ERRORS });
    dispatch({ type: ADMIN_TEST_ORDER_LOADING_LIST_DATA });

    const query = testOrderParams.query ? testOrderParams.query : "";
    testOrderParams.query = query;
    config.params = testOrderParams;

    const res = await axios.get(`/api/admin/orders`, config);
    dispatch({
      type: ADMIN_TEST_ORDER_SEARCH_PARAMATERS_UPDATE,
      payload: testOrderParams,
    });
    dispatch({
      type: ADMIN_TEST_ORDER_LIST_UPDATED,
      payload: res.data.response[0],
    });
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err.response);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: ADMIN_TEST_ORDER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  }
};

// Create Test Order
export const create = (formData, history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnTestOrderSubmit());

  const config = {
    headers: {
      "Content-Type": "application/json", // multipart/form-data;
    },
  };

  try {
    //console.log("create employer formData", formData);

    // let params = new window.FormData();
    // for(let i in formData){
    //   params.append(i, formData[i]);
    // }

    const res = await axios.post(`/api/admin/orders`, formData, config);

    if (res.data.status === true) {
      dispatch({
        type: ADMIN_TEST_ORDER_CREATED,
        payload: res.data.response,
      });
      // dispatch({ type: REMOVE_ALERT });
      dispatch(
        setAlert(res.data.message || "Admin - Test Order Created.", "success")
      );

      const createdOrderIDs = res.data.response;
      if (!createdOrderIDs.length) {
        history.push("/admin/orders");
      } else if (createdOrderIDs.length > 1) {
        history.push("/admin/orders");
      } else {
        history.push(`/admin/orders/${createdOrderIDs[0]}/edit`);
      }
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: ADMIN_TEST_ORDER_ERROR });
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (
        err.response.data &&
        (err.response.data.tokenStatus === 0 ||
          err.response.data.tokenStatus === 2)
      ) {
        dispatch(logout());
      } else {
        dispatch({
          type: ADMIN_TEST_ORDER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Get Test Order by id
export const getTestOrderById = (test_order_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnTestOrderSubmit());
    const res = await axios.get(`/api/admin/orders/${test_order_id}`, config);
    await dispatch({
      type: ADMIN_TEST_ORDER_GET_BY_ID,
      payload: res.data.response,
    });
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    // console.log(err.response);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: ADMIN_TEST_ORDER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Edit test order
export const edit = (formData, history, test_order_id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnTestOrderSubmit());

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    console.log("edit test order formData", formData);

    const res = await axios.put(
      `/api/admin/orders/${test_order_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: ADMIN_TEST_ORDER_UPDATED,
        payload: res.data.response,
      });
      dispatch(setAlert(res.data.message || "Test Order Updated.", "success"));
      // history.push("/admin/orders");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: ADMIN_TEST_ORDER_ERROR });
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: ADMIN_TEST_ORDER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Edit test order
export const saveOrderEmployerInfo =
  (formData, test_order_id) => async (dispatch) => {
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnTestOrderSubmit());

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(
        `/api/admin/orders/${test_order_id}/employer`,
        formData,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: ADMIN_TEST_ORDER_UPDATED,
          payload: res.data.response,
        });
        dispatch(
          setAlert(
            res.data.message || "Organization information updated.",
            "success"
          )
        );
        // history.push("/admin/orders");
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch({ type: ADMIN_TEST_ORDER_ERROR });
          dispatch(setAlert(res.data.message, "danger"));
          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.path));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      // console.log(err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: ADMIN_TEST_ORDER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

export const saveOrderPhysicianInfo =
  (formData, test_order_id) => async (dispatch) => {
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnTestOrderSubmit());

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(
        `/api/admin/orders/${test_order_id}/physician`,
        formData,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: ADMIN_TEST_ORDER_UPDATED,
          payload: res.data.response,
        });
        dispatch(
          setAlert(res.data.message || "Order Physician updated.", "success")
        );
        // history.push("/admin/orders");
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch({ type: ADMIN_TEST_ORDER_ERROR });
          dispatch(setAlert(res.data.message, "danger"));
          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.path));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      // console.log(err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: ADMIN_TEST_ORDER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

// save Order test info
export const saveTestInfo = (formData, order_id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnTestOrderSubmit());

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.put(
      `/api/admin/orders/${order_id}/test-info`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: ADMIN_TEST_ORDER_UPDATED,
        payload: res.data.response,
      });
      dispatch(setAlert(res.data.message || "Test Order Updated.", "success"));
      // history.push("/admin/orders");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: ADMIN_TEST_ORDER_ERROR });
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: ADMIN_TEST_ORDER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

export const saveOrderPatientData =
  (formData, order_id) => async (dispatch) => {
    dispatch({ type: REMOVE_ERRORS });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(
        `/api/admin/orders/${order_id}/patient-info`,
        formData,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: ADMIN_TEST_ORDER_UPDATED,
          payload: res.data.response,
        });
        dispatch(
          setAlert(
            res.data.message || "Order Patient information updated.",
            "success"
          )
        );
        // history.push("/admin/orders");
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch({ type: ADMIN_TEST_ORDER_ERROR });
          dispatch(setAlert(res.data.message, "danger"));
          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.path));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      // console.log(err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: ADMIN_TEST_ORDER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

export const saveOrderProductsInfo =
  (formData, order_id) => async (dispatch) => {
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnTestOrderSubmit());

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(
        `/api/admin/orders/${order_id}/product-info`,
        formData,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: ADMIN_TEST_ORDER_UPDATED,
          payload: res.data.response,
        });
        dispatch(
          setAlert(res.data.message || "Test Order Updated.", "success")
        );
        // history.push("/admin/orders");
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch({ type: ADMIN_TEST_ORDER_ERROR });
          dispatch(setAlert(res.data.message, "danger"));
          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.path));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      // console.log(err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: ADMIN_TEST_ORDER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

// Schedule test order
export const saveOrderStaff = (formData, order_id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    console.log("schedule test order formData", formData);

    const res = await axios.put(
      `/api/admin/orders/${order_id}/set-staff`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: ADMIN_TEST_ORDER_UPDATED,
        payload: res.data.response,
      });
      dispatch(
        setAlert(res.data.message || "Test Order staff updated.", "success")
      );
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: ADMIN_TEST_ORDER_ERROR });
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: ADMIN_TEST_ORDER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Delete Test Order
export const deleteTestOrder = (test_order_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.delete(
      `/api/admin/orders/${test_order_id}`,
      config
    );
    // dispatch({ type: REMOVE_ALERT });

    dispatch({
      type: ADMIN_TEST_ORDER_DELETE,
      payload: test_order_id,
    });
    dispatch(setAlert(res.data.message || "Test Order deleted.", "success"));
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: ADMIN_TEST_ORDER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// MARK COLLECTED Test Order
export const markCollected = (order_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch({ type: ADMIN_TEST_ORDER_LOADING_LIST_DATA });
    const res = await axios.put(
      `/api/admin/orders/${order_id}/mark-collected`,
      config
    );
    // dispatch({ type: REMOVE_ALERT });
    await dispatch({
      type: ADMIN_TEST_ORDER_CHANGE_STATUS,
      payload: res.data.response,
    });
    dispatch(
      setAlert(res.data.message || "Marked Test Order Collected.", "success")
    );
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: ADMIN_TEST_ORDER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// MARK COMPLETED Test Order
export const markCompleted = (test_order_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch({ type: ADMIN_TEST_ORDER_LOADING_LIST_DATA });
    const res = await axios.put(
      `/api/admin/orders/${test_order_id}/mark-completed`,
      config
    );
    // dispatch({ type: REMOVE_ALERT });
    await dispatch({
      type: ADMIN_TEST_ORDER_CHANGE_STATUS,
      payload: res.data.response,
    });
    dispatch(
      setAlert(res.data.message || "Marked Test Order Completed.", "success")
    );
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: ADMIN_TEST_ORDER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Delete Staff
export const cancelSave = (history) => async (dispatch) => {
  // dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("/admin/orders");
};

// Dispatch Loading
export const loadingOnTestOrderSubmit = () => async (dispatch) => {
  await dispatch({ type: ADMIN_TEST_ORDER_LOADING_ON_SUBMIT });
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch({ type: RESET });
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch({ type: ADMIN_TEST_ORDER_ERROR });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

// Load Page/Show Page
export const loadPage = () => async (dispatch) => {
  await dispatch({ type: LOAD_PAGE });
};

// reset errors
export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};

// page not found
export const notFound = (history) => async (dispatch) => {
  history.push("/admin/page-not-found");
};

//get employers list
export const getEmployersList = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(`/api/admin/organizations/list`, config);

    dispatch({
      type: ADMIN_TEST_ORDER_EMPLOYERS_LIST,
      payload: res.data.response,
    });
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err.response);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: ADMIN_TEST_ORDER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  }
};

export const getAdminStaffList = (role, options) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    let caller = `/api/admin/staff/list/${role}`;
    if (options && Object.keys(options).length > 0) {
      caller = `${caller}?${new URLSearchParams(options).toString()}`;
    }

    const res = await axios.get(caller, config);

    switch (role) {
      case STAFF_ROLE:
        dispatch({
          type: ADMIN_TEST_ORDER_STAFF_LIST,
          payload: res.data.response,
        });
        break;
      case PHYSICIAN_ROLE:
        dispatch({
          type: ADMIN_TEST_ORDER_PHYSICIAN_LIST,
          payload: res.data.response,
        });
        break;
      case PHLEBOTOMIST_ROLE:
        dispatch({
          type: ADMIN_TEST_ORDER_PHLEBOTOMIST_LIST,
          payload: res.data.response,
        });
        break;
      case NURSE_ROLE:
        dispatch({
          type: ADMIN_TEST_ORDER_NURSE_LIST,
          payload: res.data.response,
        });
        break;
      default:
        break;
    }

    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err.response);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: ADMIN_TEST_ORDER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  }
};

// Schedule test order
export const scheduleTestOrder =
  (formData, history, test_order_id) => async (dispatch) => {
    dispatch({ type: REMOVE_ERRORS });
    // dispatch(loadingOnTestOrderSubmit());

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      console.log("schedule test order formData", formData);

      const res = await axios.put(
        `/api/admin/orders/${test_order_id}/schedule`,
        formData,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: ADMIN_TEST_ORDER_UPDATED,
          payload: res.data.response,
        });
        dispatch(
          setAlert(res.data.message || "Test Order Scheduled.", "success")
        );
        // history.push("/admin/orders");
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch({ type: ADMIN_TEST_ORDER_ERROR });
          dispatch(setAlert(res.data.message, "danger"));
          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.path));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      // console.log(err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: ADMIN_TEST_ORDER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

// Cancel Test Order
export const cancelTestOrder =
  (formData, test_order_id) => async (dispatch) => {
    try {
      dispatch({
        type: ADMIN_TEST_ORDER_CANCEL_SUBMIT,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.put(
        `/api/admin/orders/${test_order_id}/cancel`,
        formData,
        config
      );

      dispatch({
        type: ADMIN_TEST_ORDER_CANCEL_COMPLETE,
      });

      if (res.data.status === true) {
        await dispatch({
          type: ADMIN_TEST_ORDER_CHANGE_STATUS,
          payload: res.data.response,
        });
        dispatch(setAlert(res.data.message, "success"));
      } else {
        console.log("error msg", res.data.errors[0].msg);
        dispatch(setAlert(res.data.errors[0].msg, "danger"));
      }
    } catch (err) {
      // console.log("In catch section", err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: ADMIN_TEST_ORDER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

// Add Notes
export const addNote = (formData, history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnNoteSubmit());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(`/api/admin/orders/notes`, formData, config);
    if (res.data.status === true) {
      dispatch({
        type: ADMIN_TEST_ORDER_NOTE_CREATED,
        payload: res.data.response,
      });
      dispatch(setAlert("Note Created.", "success"));
      history.push("/admin/orders/notes");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: ADMIN_TEST_ORDER_NOTE_ERROR });
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: ADMIN_TEST_ORDER_NOTE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Get Notes by order id
export const getNotesByOrderId = (order_id) => async (dispatch) => {
  //dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnNoteSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`/api/admin/orders/${order_id}/notes`, config);
    dispatch({
      type: ADMIN_TEST_ORDER_GET_NOTES_BY_ORDER_ID,
      payload: res.data.response,
    });
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: ADMIN_TEST_ORDER_NOTE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Dispatch Loading
export const loadingOnNoteSubmit = () => async (dispatch) => {
  await dispatch({ type: ADMIN_TEST_ORDER_LOADING_ON_NOTE_SUBMIT });
};

// create Recurring Order
export const createRecurringOrder =
  (formData, history, test_order_id) => async (dispatch) => {
    dispatch({ type: REMOVE_ERRORS });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      console.log("schedule test order formData", formData);

      const res = await axios.put(
        `/api/admin/orders/${test_order_id}/create-recurring-order`,
        formData,
        config
      );
      if (res.data.status === true) {
        // dispatch({
        //   type: ADMIN_TEST_ORDER_UPDATED,
        //   payload: res.data.response
        // });
        dispatch(
          setAlert(res.data.message || "Recurring Order created.", "success")
        );
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch({ type: ADMIN_TEST_ORDER_ERROR });
          dispatch(setAlert(res.data.message, "danger"));
          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.path));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      // console.log(err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: ADMIN_TEST_ORDER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

// Get result file
export const getResultAction =
  (order_id, member_id, result_id) => async (dispatch) => {
    try {
      const config = { responseType: "blob" };
      dispatch({ type: REMOVE_ERRORS });
      const res = await axios.get(
        `/api/admin/orders/${order_id}/get-result/${member_id}/${result_id}?timestamp=${Date.now()}`,
        config
      );

      return res.data;
    } catch (err) {
      console.log(err);
      dispatch(
        setAlert(
          "Either the file does not exist or something went wrong.",
          "danger"
        )
      );
    }
  };

export const deleteResultAction =
  (order_id, order_item_id, result_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      dispatch({ type: REMOVE_ERRORS });
      const res = await axios.delete(
        `/api/admin/orders/${order_id}/delete-result/${result_id}`,
        config
      );

      await dispatch({
        type: ADMIN_TEST_ORDER_RESULT_DELETE_BY_ID,
        payload: {
          result: res.data.response,
          order_item_id,
        },
      });

      dispatch(setAlert(res.data.message || "Result deleted.", "success"));
    } catch (err) {
      console.log(err);
      dispatch(setAlert("Error in deleting result.", "danger"));
    }
  };

export const downloadMROPdf = (order_id, member_id) => async (dispatch) => {
  try {
    const config = {
      responseType: "blob",
    };

    const res = await axios.get(
      `/api/admin/orders/${order_id}/get-mro-pdf/${member_id}?timestamp=${Date.now()}`,
      config
    );

    return res.data;
  } catch (err) {
    console.log(err);
    dispatch(
      setAlert(
        "Either the file doesn't exist or something went wrong.",
        "danger"
      )
    );
  }
};

export const regenerateMROPDFAction =
  (order_id, member_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.get(
        `/api/admin/orders/${order_id}/regenerate-mro-pdf/${member_id}?timestamp=${Date.now()}`,
        config
      );

      if (res.data.status === true) {
        dispatch(
          setAlert(
            res.data.message ||
              "MRO toxic report started regenerating. It may take few minutes",
            "success"
          )
        );
      }

      return res.data ? res.data : { status: false };
    } catch (err) {
      console.log(err);
      dispatch(
        setAlert(
          "Either the file doesn't exist or something went wrong.",
          "danger"
        )
      );
    }
  };

export const regenerateDrugCertificateAction =
  (order_id, member_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.get(
        `/api/admin/orders/${order_id}/regenerate-drug-certificate/${member_id}?timestamp=${Date.now()}`,
        config
      );

      if (res.data.status === true) {
        dispatch(
          setAlert(
            res.data.message ||
              "Drug certificate started regenerating. It may take few minutes",
            "success"
          )
        );
      }

      return res.data ? res.data : { status: false };
    } catch (err) {
      console.log(err);
      dispatch(
        setAlert(
          "Either the file doesn't exist or something went wrong.",
          "danger"
        )
      );
    }
  };

export const removeOrderItemAction =
  (order_id, order_item_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      dispatch({ type: REMOVE_ERRORS });

      const res = await axios.put(
        `/api/admin/orders/${order_id}/remove-order-item/${order_item_id}`,
        config
      );

      await dispatch({
        type: ADMIN_TEST_ORDER_ORDER_ITEM_REMOVED,
        payload: res.data.response,
      });

      dispatch(
        setAlert(
          res.data.message || "Orderm item removed from the order.",
          "success"
        )
      );
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

export const scheduleOrderDelivery =
  (formData, order_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      dispatch({ type: REMOVE_ERRORS });

      const res = await axios.put(
        `/api/admin/orders/${order_id}/schedule-delivery`,
        formData,
        config
      );

      if (res.data.status === true) {
        await dispatch({
          type: ADMIN_TEST_ORDER_GET_BY_ID,
          payload: res.data.response,
        });

        dispatch(
          setAlert(res.data.message || "Order delivery scheduled", "success")
        );
      }

      return res ? res.data : {};
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

//get patient data for CSV
export const getLabCSVData = (test_order_id) => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    const res = await axios.get(
      `/api/admin/orders/${test_order_id}/get-patient-csv-data`,
      config
    );
    // console.log("res.data.response", res.data.response);
    await dispatch({
      type: ADMIN_TEST_ORDER_PATIENT_DATA,
      payload: res.data.response,
    });
    // dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    err.response &&
      dispatch({
        type: ADMIN_TEST_ORDER_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
  }
};

//Bulk operaions on patients
export const doPatientBulkOperation =
  (order_id, formData) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `/api/admin/orders/${order_id}/doPatientBulkOperation`,
        formData,
        config
      );
      if (res.data.status === true) {
        dispatch(setAlert(res.data.message, "success"));
      } else {
        console.log("error msg", res.data.errors[0].msg);
        dispatch(setAlert(res.data.errors[0].msg, "danger"));
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: ADMIN_TEST_ORDER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

// save barcode to patient
export const saveBarcode =
  (formData, test_order_id, patientID) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.put(
        `/api/admin/orders/${test_order_id}/${patientID}/save-barcode`,
        formData,
        config
      );

      if (res.data.status === true) {
        await dispatch({
          type: ADMIN_TEST_ORDER_BARCODE_ADDED,
          payload: {
            orderItemID: res.data.response.orderItemID,
            barcode: res.data.response.barcode,
          },
        });
        dispatch(setAlert(res.data.message, "success"));
        return true;
      } else {
        console.log("error msg", res.data.errors[0].msg);
        dispatch(setAlert(res.data.errors[0].msg, "danger"));
        return false;
      }
    } catch (err) {
      // console.log("In catch section", err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: ADMIN_TEST_ORDER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
      return false;
    }
  };

// Archive Test Order
export const archiveTestOrder = (test_order_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // dispatch({ type: ADMIN_TEST_ORDER_LOADING_LIST_DATA });
    const res = await axios.put(
      `/api/admin/orders/${test_order_id}/archive`,
      config
    );
    // dispatch({ type: REMOVE_ALERT });
    await dispatch({
      type: ADMIN_TEST_ORDER_ARCHIVED,
      payload: test_order_id,
    });
    dispatch(setAlert(res.data.message || "Test Order archived.", "success"));
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: ADMIN_TEST_ORDER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Get barcoding status
export const getBarcodingStatus = (order_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `/api/admin/orders/${order_id}/barcoding-status`,
      config
    );
    dispatch({
      type: ADMIN_TEST_ORDER_BARCODING_STATUS,
      payload: res.data.response.barcoding,
    });
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: ADMIN_TEST_ORDER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

export const generateTestOrderAccessToken = (order_id) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.get(
      `/api/admin/orders/${order_id}/generate-test-order-access-token`,
      config
    );
    return res.data.response;
  } catch (err) {
    console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: ADMIN_TEST_ORDER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

export const downloadRegistrationFormAction =
  (order_id, order_patient_id) => async (dispatch) => {
    try {
      const config = { responseType: "blob" };
      dispatch({ type: REMOVE_ERRORS });
      const res = await axios.get(
        `/api/admin/orders/${order_id}/get-registration-certificate/${order_patient_id}?timestamp=${Date.now()}`,
        config
      );

      return res.data;
    } catch (err) {
      console.log(err);
      dispatch(
        setAlert(
          "Either the file does not exist or something went wrong.",
          "danger"
        )
      );
    }
  };

export const markMROReviewComplete =
  ({ order_id }) =>
  async (dispatch) => {
    try {
      const config = { headers: { "Content-Type": "application/json" } };
      dispatch({ type: REMOVE_ERRORS });
      const res = await axios.put(
        `/api/admin/orders/${order_id}/mro-review-complete`,
        config
      );

      if (res.data.status === true) {
        await dispatch({
          type: ADMIN_TEST_ORDER_CHANGE_STATUS_SPECIFIC,
          payload: res.data.response.status,
        });

        return dispatch(
          setAlert("Test order mark as delivered successfully.", "success")
        );
      } else {
        console.log("error msg", res.data.message);
        dispatch(setAlert(res.data.message, "danger"));
        return false;
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: ADMIN_TEST_ORDER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

//get physicians list
export const getEmployerPhysiciansList = (employer_id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `/api/admin/orders/${employer_id}/physicians-list`,
      config
    );

    dispatch({
      type: ADMIN_TEST_ORDER_PHYSICIAN_LIST,
      payload: res.data.response,
    });
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err.response);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: ADMIN_TEST_ORDER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  }
};

const get_params = (formData) => {
  let params = new window.FormData();
  const formFields = [
    "registrationType",
    "line_1",
    "line_2",
    "zip_code",
    "county",
    "user_id",
    "iso",
    "secondaryUsername",
    "fullName",
    "phone",
    "email",
    "dob",
    "ccode",
    "employerId",
    "isChild",
    "paymentBy",
    "gender",
    "insuranceCompany",
    "subscriberId",
    "parentsApproval",
    "collectInsuranceData",
    "insuranceDetails",
    "isPregnant",
    "referralAuthority",
    "icdValidCode",
    "ethnicity",
    "ssn",
    "jobNumber",
    "race",
    "alternativePhone",
    "alternativePhoneISO",
    "alternativePhoneCCode",
    "alternativePhone_full",
    "occupation",
    "employedInHealthcare",
    "memberPassword",
    "vaccineType",
    "firstDoseDone",
    "firstDoseDate",
    "secondDoseDone",
    "secondDoseDate",
    "boosterDone",
    "boosterDate",
    "vaccinationCardFront",
    "vaccinationCardBack",
    "signatureImgURL",
  ];
  const formObjectFields = [
    "a_city",
    "primaryInsurance",
    "secondaryInsurance",
    "companyLocation",
  ];
  formFields.forEach((field) => {
    if (formData[field]) {
      params.append(field, formData[field]);
    }
  });
  formObjectFields.forEach((field) => {
    if (formData[field]) {
      if (field === "companyLocation")
        params.append(field, formData[field].value);
      else if (field === "primaryInsurance" || field === "secondaryInsurance") {
        Object.keys(formData[field]).forEach((item) => {
          params.append(field + "[" + item + "]", formData[field][item]);
        });
      } else params.append(field, JSON.stringify(formData[field]));
    }
  });

  return params;
};

// Patient Registration
export const registerPatient = (formData) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  // dispatch(loadingOnEmployeeSubmit());
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  try {
    const params = get_params(formData);
    const res = await axios.post(
      `/api/admin/orders/register-patient`,
      params,
      config
    );
    if (res.data.status === true) {
      dispatch(
        setAlert(
          "Registration successful. An email has been sent to you with the login information.",
          "success"
        )
      );
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: ADMIN_TEST_ORDER_ERROR });
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    err.response &&
      dispatch({
        type: ADMIN_TEST_ORDER_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    dispatch(
      setAlert(err.response.data.message || err.response.statusText, "danger")
    );
  }
};

// Get Employer short info by id
export const getEmployerShortInfoById = (id) => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    dispatch({ type: REMOVE_ERRORS });
    let res = await axios.get(`/api/admin/orders/short-info-org/${id}`, config);

    await dispatch({
      type: ADMIN_TEST_ORDER_EMPLOYEE_EMPLOYER_INFO,
      payload: res.data.response,
    });
    return res.data.response;
  } catch (err) {
    console.log(err);
    err.response &&
      dispatch({
        type: ADMIN_TEST_ORDER_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });

    if (err.response && err.response.status === 401) {
      dispatch(setAlert("Invalid Access", "danger"));
    }

    return err?.response;
  }
};

export const getInsuranceNameList = (id) => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    const res = await axios.get(
      `/api/admin/orders/employee/insuranceNameList`,
      config
    );
    return res.data.response;
  } catch (err) {
    console.log(err);
  }
};
