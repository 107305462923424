import { employmentTest } from "constants/index";

import { reasonToCancelList, TEST_ORDER_STATUS } from "constants/index";

import store from "../store";

// parse extension with filename
export const parseExtension = (filename) => {
  let extensionsList = filename.split(".");
  let extLen = extensionsList.length;
  return extensionsList[extLen - 1];
};

export const uniqueString = (length = 16) => {
  let randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var result = "";
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
};

export const extractNameto3 = (fullname) => {
  try {
    fullname = fullname.trim();
    if (fullname) {
      let extractedInitials = fullname.split(" ");
      extractedInitials = extractedInitials.filter((n) => n);
      let returnName = {
        firstName: extractedInitials[0],
        lastName: extractedInitials[extractedInitials.length - 1],
        middleName: "",
      };
      extractedInitials.shift();
      extractedInitials.pop();
      if (extractedInitials.length) {
        returnName.middleName = extractedInitials.join(" ");
      }

      return returnName;
    }
    return {
      firstName: "",
      lastName: "",
      middleName: "",
    };
  } catch (err) {
    return {
      firstName: "",
      lastName: "",
      middleName: "",
    };
  }
};

export const prepareStaffAddress = (p) => {
  let add = p.address
    ? p.address.line_1 + (p.address.line_2 ? p.address.line_2 : "")
    : "";

  let loc =
    p.location && p.location.city_name
      ? p.location.city_name + ", " + p.location.state
      : "";

  add = `${add} ${loc}`;

  return add.trim() ? ` - ${add}` : "";
};

export const displayOrgName = (orgInfo) => {
  if (!orgInfo) return;
  if (!orgInfo.companyBuildingName) {
    return orgInfo.companyName;
  } else {
    return `${orgInfo.companyName}`; //  - ${orgInfo.companyBuildingName}
  }
};

export const capitalize = ([first, ...rest]) => {
  return first.toUpperCase() + rest.join("").toLowerCase();
};

export const capitalizeFirst = ([first, ...rest]) => {
  return first.toUpperCase() + rest.join("");
};

export const getTestName = (testID) => {
  if (!testID) return "";

  const filtered = employmentTest.find((each) => each.value === testID);

  if (!filtered) return "";

  return filtered.label;
};

export const getTestNameBySlug = (slug, category) => {
  const globalDrugTestsList = store.getState().testConfig.drugTestsGlobal;
  const labTestsGlobal = store.getState().testConfig.labTestsGlobal;
  const medicalTestsGlobal = store.getState().testConfig.medicalTestsGlobal;

  let globalTestList = [];
  switch (category) {
    case "drug":
      globalTestList = globalDrugTestsList;
      break;
    case "customize":
      return slug;
    case "lab":
      labTestsGlobal.forEach((each) => {
        globalTestList = [...globalTestList, ...each.testsIncluded];
      });
      break;
    case "medical":
      medicalTestsGlobal.forEach((each) => {
        globalTestList = [...globalTestList, ...each.testsIncluded];
      });
      break;
    default:
      globalTestList = [];
  }

  const filtered = globalTestList.find((each) => each.id === slug);
  if (!filtered) return slug;

  return filtered.name;
};

export const getReasonToCancel = (reason) => {
  if (!reason) return "";

  const filtered = reasonToCancelList.find((each) => each.value === reason);

  if (!filtered) return "";

  return filtered.label;
};

export const isAllegisOrg = (employer) => {
  return employer && employer.orgParent === "Allegis" ? true : false;
};

export const makeFullName = (first_name, middle_name, last_name) => {
  let name_array = [first_name, middle_name, last_name];

  name_array = name_array.map((each) => (each ? each.trim() : ""));

  return name_array.join(" ");
};

export const getProductsByCategory = (cat_ID) => {
  const productsListGlobal = store.getState().testConfig.productsListGlobal;
  return productsListGlobal.filter((g) => g.category === cat_ID);
};

export const getProductsWithGroups = () => {
  const productsListGlobal = store.getState().testConfig.productsListGlobal;

  let directProducts = [];
  productsListGlobal.forEach((group) => {
    const products = group.products.map((product) => ({
      group: {
        groupID: group.groupID,
        groupName: group.groupName,
        cat_ID: group.category,
      },
      ...product,
    }));
    directProducts = [...directProducts, ...products];
  });

  return directProducts;
};

export const getProductByID = (product_ID) => {
  const productsListGlobal = store.getState().testConfig.productsListGlobal;

  let globalTestList = [];
  productsListGlobal.forEach((group) => {
    const products = group.products.map((product) => ({
      group: {
        groupID: group.id,
        groupName: group.name,
      },
      ...product,
    }));
    globalTestList = [...globalTestList, ...products];
  });

  const filtered = globalTestList.find((each) => each.id === product_ID);
  if (!filtered) return {};

  return filtered;
};

export const getCategoryByID = (cat_ID) => {
  const CategoriesGlobal = store.getState().common.categoriesOfBusinessGlobal;

  if (!CategoriesGlobal.length) return {};

  const filtered = CategoriesGlobal.find((cat) => cat.cat_ID === cat_ID);

  if (!filtered) return {};

  return filtered;
};

export const getTypeByID = (type_ID) => {
  const TypesGlobal = store.getState().common.typesOfBusinessGlobal;

  if (!TypesGlobal.length) return {};

  const filtered = TypesGlobal.find((type) => type.type_ID === type_ID);

  if (!filtered) return {};

  return filtered;
};

export const todayStandardDate = () => {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return yyyy + "-" + mm + "-" + dd;
};

export const getOrderStatusText = (status) => {
  const orderStatus = TEST_ORDER_STATUS.find((st) => st.value == status);

  if (!orderStatus) return "";

  return orderStatus.label;
};

export const formatHour = (hour, minutes = 0) => {
  const period = hour < 12 ? "AM" : "PM";
  const hour12 = hour % 12 === 0 ? 12 : hour % 12;
  const minutesStr = minutes.toString().padStart(2, "0");
  return `${hour12}:${minutesStr} ${period}`;
};

export const getTimeSlots = () => {
  const timeSlots = [];
  for (let i = 8; i < 20; i++) {
    const startHour1 = formatHour(i, 0);
    const endHour1 = formatHour(i, 30);
    const startHour2 = formatHour(i, 30);
    const endHour2 = formatHour(i + 1, 0);

    timeSlots.push({
      label: `${startHour1} to ${endHour1}`,
      value: `${i}:00`,
    });

    timeSlots.push({
      label: `${startHour2} to ${endHour2}`,
      value: `${i}:30`,
    });
  }

  return timeSlots;
};
