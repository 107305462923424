import axios from "axios";
import {
  UPDATE_BUSINESS_TYPES,
  UPDATE_BUSINESS_CATEGORIES,
} from "actions/types";

export const getBusinessTypes = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(`/api/common/business-types`, config);

    if (res.data.status === true) {
      dispatch({
        type: UPDATE_BUSINESS_TYPES,
        payload: res.data.response,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getBusinessCategories = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(`/api/common/business-categories`, config);

    if (res.data.status === true) {
      dispatch({
        type: UPDATE_BUSINESS_CATEGORIES,
        payload: res.data.response,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getGroupsByCategory = (category_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(`/api/common/${category_id}/groups`, config);

    return res.data && res.data.response ? res.data.response : [];
  } catch (err) {
    console.log(err);
  }
};

export const getProductsByGroup = (group_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(`/api/common/${group_id}/products`, config);

    return res.data && res.data.response ? res.data.response : [];
  } catch (err) {
    console.log(err);
  }
};
