import {
  ADMIN_LAB_CREATED,
  ADMIN_LAB_ERROR,
  ADMIN_LAB_DELETE,
  ADMIN_LAB_UPDATED,
  ADMIN_LAB_LIST_UPDATED,
  ADMIN_LAB_GET_BY_ID,
  ADMIN_LAB_SEARCH_PARAMATERS_UPDATE,
  ADMIN_LAB_CHANGE_STATUS,
  ADMIN_LAB_LOADING_ON_SUBMIT,
  RESET,
  LOAD_PAGE,
  ADMIN_LAB_LOADING_LIST_DATA,
  ADMIN_LAB_LOADING_PRODUCTS_LIST_DATA,
  ADMIN_LAB_PRODUCT_SEARCH_PARAMATERS_UPDATE,
  ADMIN_LAB_PRODUCT_LIST_UPDATED,
  ADMIN_LAB_LOADING_ON_PRODUCT_SUBMIT,
  ADMIN_LAB_PRODUCT_UPDATED,
  ADMIN_LAB_PRODUCT_CHANGE_STATUS,
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  labsList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentLab: null,
  loadingLabList: true,
  loadingLab: true,
  loadingListData: true,
  performDelete: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters: [],
  },
  currentLabProduct: null,
  loadingLabProduct: true,
  sortingProductParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  },
  labProductsList: {
    page: 1,
    data: [],
    count: 0,
  },
  loadingLabProductsList: true,
  loadingLabProductsListData: true,
  loadingLabProduct: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return {
        ...initialState,
        loadingLab: state.loadingLab,
        loadingLabList: state.loadingLabList,
        loadingListData: state.loadingListData,
        loadingLabProductsListData: state.loadingLabProductsListData,
      };
    case LOAD_PAGE:
      return {
        ...state,
        loadingLab: false,
      };
    case ADMIN_LAB_CREATED:
      return {
        ...state,
        loadingLab: false,
      };
    case ADMIN_LAB_UPDATED:
      return {
        ...state,
        currentLab: payload,
        sortingParams: initialState.sortingParams,
        loadingLab: false,
      };
    case ADMIN_LAB_DELETE:
      const currentCount = state.labsList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.labsList.page);
      let remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      remainingPages = remainingPages ? remainingPages : 1;
      return {
        ...state,
        labsList: {
          data: state.labsList.data.filter((lab) => lab._id !== payload),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        loadingLabList: false,
        performDelete: true,
      };
    case ADMIN_LAB_ERROR:
      return {
        ...state,
        error: payload,
        loadingLab: false,
        loadingListData: false,
        performDelete: false,
        loadingLabProductsListData: false,
      };
    case ADMIN_LAB_GET_BY_ID:
      return {
        ...state,
        currentLab: payload,
        loadingLab: false,
      };
    case ADMIN_LAB_LIST_UPDATED:
      return {
        ...state,
        labsList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        // loadingLab: true,
        loadingLabList: false,
        loadingListData: false,
        performDelete: false,
      };
    case ADMIN_LAB_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };
    case ADMIN_LAB_CHANGE_STATUS:
      return {
        ...state,
        loadingListData: false,
        labsList: {
          ...state.labsList,
          data: state.labsList.data.map((lab) =>
            lab._id === payload._id ? { ...lab, status: payload.status } : lab
          ),
        },
      };
    case ADMIN_LAB_LOADING_LIST_DATA:
      return {
        ...state,
        loadingListData: true,
      };
    case ADMIN_LAB_LOADING_ON_SUBMIT:
      return {
        ...state,
        loadingLab: true,
      };

    case ADMIN_LAB_LOADING_PRODUCTS_LIST_DATA:
      return {
        ...state,
        loadingLabProductsListData: true,
      };
    case ADMIN_LAB_PRODUCT_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        currentLabProduct: payload,
        sortingProductParams: { ...payload },
      };
    case ADMIN_LAB_PRODUCT_LIST_UPDATED:
      return {
        ...state,
        currentLabProduct: payload,
        labProductsList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        loadingLabProductsList: false,
        loadingLabProductsListData: false,
        // performProductsPricingDelete: false,
      };
    case ADMIN_LAB_LOADING_ON_PRODUCT_SUBMIT:
      return {
        ...state,
        loadingLabProduct: true,
        loadingLabProductsListData: true,
      };
    case ADMIN_LAB_PRODUCT_UPDATED:
      return {
        ...state,
        currentLabProduct: payload,
        sortingProductParams: initialState.sortingProductParams,
        loadingLabProduct: false,
        loadingLabProductsList: false,
        loadingLabProductsListData: false,
      };
    case ADMIN_LAB_PRODUCT_CHANGE_STATUS:
      return {
        ...state,
        loadingLabProductsListData: false,
        labProductsList: {
          ...state.labProductsList,
          data: state.labProductsList.data.map((product) =>
            product._id === payload._id
              ? { ...product, status: payload.status }
              : product
          ),
        },
      };
    default:
      return state;
  }
}
